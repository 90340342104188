a {
  color: #fff;
}

a:hover {
  text-decoration: none;
}

.container {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: 12px;
  line-height: 20px;
  color: white;
  min-width: 375px;
  
}

.content {
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 90%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.albums {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.cover-image {
  width: 100%;
  max-width: 300px;
}

.album-title {
  font-size: 14px;
}

.download-link {
  margin-right: 40px;
}

.media-links {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.media-link {
  display: flex;
  margin-right: 24px;
  margin-bottom: 10px;
}

.media-link:first-child {
  margin-left: 0;
}

.media-icon {
  margin-right: 4px;
}

.divider {
  width:90%;
  height: 1px;
  background-color: #1b1b1b;
  margin-top: 30px;
  margin-bottom: 24px;
}

@media (max-width: 699px) { 
  .header {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .albums {
    justify-content: center;
  }

  .cover-image {
    max-width: 100%;
  }

  .media-links {
    justify-content: center;
  }
}

